const firebaseConfig = {
    apiKey: "AIzaSyCjMrhcHnHPBUNJWdUTwA114VNsx_ycuYQ",
    authDomain: "geniustools-io.firebaseapp.com",
    projectId: "geniustools-io",
    storageBucket: "geniustools-io.appspot.com",
    messagingSenderId: "985549360182",
    appId: "1:985549360182:web:98b4cf3525707cdbe31c97",
    measurementId: "G-6CMF2YWEQT"
};

// Importações modulares do Firebase v9+
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const app = initializeApp(firebaseConfig);
// Exporta as instâncias dos serviços do Firebase que você vai usar
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();