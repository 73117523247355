import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

const MinhasOfertasLista = () => import("@/pages/MinhasOfertas/MinhasOfertasLista.vue");
const MinhasOfertasNew = () => import("@/pages/MinhasOfertas/MinhasOfertasNew.vue");
const MinhasOfertasEdit = () => import("@/pages/MinhasOfertas/MinhasOfertasEdit.vue");

const TopOfertasLista = () => import("@/pages/TopOfertas/TopOfertasLista.vue");
const TopOfertasNew = () => import("@/pages/TopOfertas/TopOfertasNew.vue");
const TopOfertasEdit = () => import("@/pages/TopOfertas/TopOfertasEdit.vue");

const OfertasModelaveisLista = () => import("@/pages/OfertasModelaveis/OfertasModelaveisLista.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/minhas-ofertas",
    children: [
      {
        path: "minhas-ofertas",
        name: "Minhas Ofertas",
        component: MinhasOfertasLista,
      },
      {
        path: "minhas-ofertas-new",
        name: "Minhas Ofertas",
        component: MinhasOfertasNew,
      },
      {
        path: "minhas-ofertas-edit/:id",
        name: "Minhas Ofertas",
        component: MinhasOfertasEdit,
      },
      {
        path: "ofertas-modelaveis",
        name: "Ofertas Modeláveis",
        component: OfertasModelaveisLista,
      },
      {
        path: "top-ofertas",
        name: "Top Ofertas",
        component: TopOfertasLista,
      },
      {
        path: "top-ofertas-new",
        name: "Minhas Ofertas",
        component: TopOfertasNew,
      },
      {
        path: "top-ofertas-edit/:id",
        name: "Minhas Ofertas",
        component: TopOfertasEdit,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
