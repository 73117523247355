import VueRouter from "vue-router";
import routes from "./routes";
import Vue from 'vue';

import store from '@/store/index';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (
    !store.getters.getUser ||
    Object.keys(store.getters.getUser).length == 0
  ) {
    Vue.prototype.$notify({
      message: "Por favor, faça o login para continuar.",
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'warning',
      timeout: 3000,
    });

    // store.commit('RESET', '');

    // window.location.href = process.env.VUE_APP_DOMAIN;
  }

  next();
});

export default router;
